<template>
  <div class="bid-panel">
    <!--    <van-tabs v-model="activeName" @click="onClick" border sticky :swipeable=false>-->
    <!--      <van-tab name="01" style="overflow-y: auto;background-color: white">-->
    <!--        <div slot="title">-->
    <!--          电煤招标-->
    <!--        </div>-->
    <div style="display: flex;background-color: white">
      <van-sidebar v-if="tagList.length>0" v-model="activeKey">
        <van-sidebar-item v-for="(item,index) in tagList" v-bind:key="index" style="background-color: #FFFFFF"
                          :title="item.title" :info="item.numbers == 0 ? '' : item.numbers"
                          @click="onChange(item.title)"/>
      </van-sidebar>
      <div style="width: 100%" v-if="this.list && this.list.length>=1">
        <van-row>
          <van-col span="1">
            <div class="verticalBar">
            </div>
          </van-col>
          <van-col span="23">
            <van-card v-for="(item,index) in list" v-bind:key="index" @click="toDetail(item)"
                      style="background-color: #FFFFFF">
              <div slot="tags">
                <van-row>
                  <van-col span="6" offset="0">
                    <van-image
                        width="80"
                        height="80"
                        :src="require('@/assets/bidPanel/'+item.image)"
                    />
                    <van-count-down millisecond :time="item.time" format="HH:mm:ss:SS"
                                    style="margin-left: 0px; color: red"/>
                  </van-col>
                  <van-col span="15" offset="3">
                    <van-row style="text-align: left; font-weight: bolder">
                      <van-col span="24" offset="0">
                        <span>{{ item.title }}</span>
                      </van-col>
                    </van-row>
                    <van-divider></van-divider>
                    <van-row style="text-align: left; margin-top: -6px">报价时间：</van-row>
                    <van-row style="text-align: left;font-weight: bolder">{{ item.endDate }}
                    </van-row>
                  </van-col>
                </van-row>
              </div>
              <div slot="footer">
                <van-button size="mini">查看详情</van-button>
              </div>
            </van-card>
          </van-col>
        </van-row>

      </div>
      <div v-else style="background-color: #FFFFFF;width: 100%">
        <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
      </div>
    </div>
    <!--      </van-tab>-->
    <!--      <van-tab name="02" style="overflow-y: auto;;background-color: white">-->
    <!--        <div slot="title">-->
    <!--          钢煤招标-->
    <!--        </div>-->
    <!--        <div style="display: flex">-->
    <!--          <van-sidebar v-model="activeKey" @change="onChange">-->
    <!--            <van-sidebar-item style="background-color: #FFFFFF" title="敬业" :info="this.jingye"/>-->
    <!--          </van-sidebar>-->
    <!--          <div style="width: 100%" v-if="this.list && this.list.length>=1">-->
    <!--            <van-row>-->
    <!--              <van-col span="1">-->
    <!--                <div class="verticalBar">-->
    <!--                </div>-->
    <!--              </van-col>-->
    <!--              <van-col span="23">-->
    <!--                <van-card v-for="(item,index) in list" v-bind:key="index" @click="toDetail(item)"-->
    <!--                          style="background-color: #FFFFFF">-->
    <!--                  <div slot="tags">-->
    <!--                    <van-row>-->
    <!--                      <van-col span="6" offset="0">-->
    <!--                        <van-image-->
    <!--                            width="80"-->
    <!--                            height="80"-->
    <!--                            :src="require('@/assets/bidPanel/'+item.image)"-->
    <!--                        />-->
    <!--                        <van-count-down millisecond :time="item.time" format="HH:mm:ss:SS"-->
    <!--                                        style="margin-left: 0px; color: red"/>-->
    <!--                      </van-col>-->
    <!--                      <van-col span="15" offset="3">-->
    <!--                        <van-row style="text-align: left; font-weight: bolder">-->
    <!--                          <van-col span="24" offset="0">-->
    <!--                            <span>{{ item.title }}</span>-->
    <!--                          </van-col>-->
    <!--                        </van-row>-->
    <!--                        <van-divider></van-divider>-->
    <!--                        <van-row style="text-align: left; margin-top: -6px">报价时间：</van-row>-->
    <!--                        <van-row style="text-align: left;font-weight: bolder">{{ item.endDate }}-->
    <!--                        </van-row>-->
    <!--                      </van-col>-->
    <!--                    </van-row>-->
    <!--                  </div>-->
    <!--                  <div slot="footer">-->
    <!--                    <van-button size="mini">查看详情</van-button>-->
    <!--                  </div>-->
    <!--                </van-card>-->
    <!--              </van-col>-->
    <!--            </van-row>-->

    <!--          </div>-->
    <!--          <div v-else style="background-color: #FFFFFF;width: 100%">-->
    <!--            <van-empty image="error" description="没有数据"/>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </van-tab>-->
    <!--    </van-tabs>-->
    <!-- 底部导航栏-->
  </div>
</template>

<script>
import {Toast} from "vant";
import {getBidInfo} from '@/api/bidPanel'
import CrmTabBar from "@/components/CrmTabBar";
import {getNumbers} from '@/api/eChnenergyTask';

export default {
  name: "BidPanel",
  components: {CrmTabBar},
  data() {
    return {
      activeName: '01',
      activeKey: 0,
      freshHeight: 0,
      tagList: [],
      list: [],
      time: 30 * 60 * 60 * 1000,
      guoneng: 0,
      huaneng: 0,
      huayuanxing: 0,
      jianfaxin: 0,
      jianfa: 0,
      sanbao: 0,
      jingye: 0,
      wuchan: 0,
      xmgm: 0,
      btnGuoneng: false,
      btnHuaneng: false,
      btnHuayuanxing: false,
      btnJianfaxin: false,
      btnJianfa: false,
      btnSanbao: false,
      btnJingye: false,
      btnWuchan: false,
      btnXmgm: false,
      btnDtjt: false,
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  watch: {},
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getNumbers({
        userNo: this.$store.getters.userId
      }).then(res => {
        this.tagList = []
        this.tagList = res
        if(res.length>0){
          getBidInfo({
            type: res[0].title
          }).then(res => {
            this.list = []
            if (res.length > 0) {
              this.list = res
              console.log(this.list)
              Toast.success('加载成功');
            } else {
              Toast.success('暂无数据');
            }
          }).catch(err => {
            console.log(err)
            Toast.fail('加载失败');
          })
        }else{
          Toast.fail('您还不具备权限！');
        }
      })
    },
    onClick() {
      console.log(this.activeName)
      if (this.activeName == '01') {
        getBidInfo({
          type: "国能"
        }).then(res => {
          this.list = []
          if (res.length > 0) {
            this.list = res
            console.log(this.list)
            Toast.success('加载成功');
          } else {
            Toast.success('暂无数据');
          }
        }).catch(err => {
          console.log(err)
          Toast.fail('加载失败');
        })
      } else {
        getBidInfo({
          type: "钢煤"
        }).then(res => {
          this.list = []
          if (res.length > 0) {
            this.list = res
            console.log(this.list)
            Toast.success('加载成功');
          } else {
            Toast.success('暂无数据');
          }
        }).catch(err => {
          console.log(err)
          Toast.fail('加载失败');
        })
      }
    },
    onChange(index) {
      getBidInfo({
        type: index
      }).then(res => {
        this.list = []
        if (res.length > 0) {
          this.list = res
          console.log(this.list)
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    toDetail(to) {
      console.log(to)
      switch (to.image) {
        case "guoneng.png":
          this.$router.push({path: "/Bulk5/" + to.id});
          break
        case "huaneng.png":
          this.$router.push({path: "/Bulk3/" + to.id});
          break
        case "huayuanxing.png":
          this.$router.push({path: "/Bulk2/" + to.id});
          break
        case "jianfaxin.png":
          this.$router.push({path: "/Bulk1/" + to.id});
          break
        case "sanbao.png":
          this.$router.push({path: "/Bulk4/" + to.id});
          break
        case "jingye.png":
          this.$router.push({path: "/Bulk6/" + to.id});
          break
        case "jianfa.png":
          this.$router.push({path: "/Bulk7/" + to.id});
          break
        case "wuchan.png":
          this.$router.push({path: "/Bulk8/" + to.id});
          break
        case "xmgm.png":
          this.$router.push({path: "/Bulk9/" + to.id});
          break
        case "dtmt.png":
          this.$router.push({path: "/Bulk10/" + to.id});
          break
        case "zlzb.png":
          this.$router.push({path: "/Bulk11/" + to.id});
          break
        case "zmhy.png":
          this.$router.push({path: "/Bulk12/" + to.id});
          break
      }

    }
  }
}
</script>

<style scoped>
.bid-panel .verticalBar {
  width: 1px;
  height: 200em;
  background: #E4E4E4;
  display: inline-block;
  margin-top: 0px;
  vertical-align: top;
  margin-right: 0px;
  margin-left: 0px;
}

</style>

