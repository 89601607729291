import request from '@/utils/request'

export function getBidInfo(formFile) {
  return request({
    url: "/api/ajax/bidPanel/getBidInfo.json",
    method: 'post',
    data: formFile,
  })
}





